import React, { useState, useEffect, useCallback, useRef } from 'react';
import SearchPlayers from '../SearchPlayers/SearchPlayers';
import latinize from 'latinize';
import html2canvas from 'html2canvas';
import defaultPlayerImg from '../../img/defaultPlayer.jpg';
import { getPossibleSolutionsArg } from '../../services/api';
import { checkPlayer } from '../../helpers/checkPlayer';
import './PlayerCombiner.css';

const backendURL = 'https://footy-247087924341.us-central1.run.app';

// Función que reparte equitativamente valores en un rango
const calculateEvenPositions = (count, start, end) => {
    if (count === 1) return [(start + end) / 2];
    const spacing = (end - start) / (count - 1);
    return Array.from({ length: count }, (_, i) => start + spacing * i);
};

// Calcula las coordenadas de la formación basándose en el objeto `positions`
const calculateFormationPositions = (positions) => {
    const verticalMapping = {
        GK: 90,
        CB: 70,
        LB: 65,
        RB: 65,
        CDM: 60,
        CM: 50,
        CAM: 40,
        LM: 45,
        RM: 45,
        LW: 25,
        RW: 25,
        ST: 20
    };
    const leftGroup = ['LB', 'LM', 'LW'];
    const rightGroup = ['RB', 'RM', 'RW'];
    const centerGroup = ['GK', 'CB', 'CDM', 'CM', 'CAM', 'ST'];
    const coords = {};
    Object.keys(positions).forEach(key => {
        const count = positions[key];
        let leftPositions = [];
        if (leftGroup.includes(key)) {
            leftPositions = calculateEvenPositions(count, 5, 30);
        } else if (rightGroup.includes(key)) {
            leftPositions = calculateEvenPositions(count, 70, 95);
        } else if (centerGroup.includes(key)) {
            leftPositions = calculateEvenPositions(count, 35, 65);
        } else {
            leftPositions = calculateEvenPositions(count, 35, 65);
        }
        const top = verticalMapping[key] !== undefined ? verticalMapping[key] : 60;
        coords[key] = leftPositions.map(left => ({ top, left }));
    });
    return coords;
};

const PlayerCombinerArg = ({ selectedTeam, positions, onResetGame = () => { } }) => {
    const fieldRef = useRef(null);

    const initializeLineup = () => {
        const init = {};
        Object.keys(positions).forEach(pos => {
            init[pos] = [];
        });
        return init;
    };

    const [lineup, setLineup] = useState(initializeLineup());
    const [teamOne, setTeamOne] = useState('');
    const [teamTwo, setTeamTwo] = useState('');
    const [error, setError] = useState('');
    const [gameWon, setGameWon] = useState(false);
    const [skipCount, setSkipCount] = useState(3);
    const [helpMessage, setHelpMessage] = useState('');
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [idPlayersOnField, setIdPlayersOnField] = useState([]);
    const [lastQuery, setLastQuery] = useState(null);
    const [currentSuggestions, setCurrentSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [freePositionsOnField, setFreePositionsOnField] = useState([]);
    const totalRequiredPlayers = Object.values(positions).reduce((acc, curr) => acc + curr, 0);
    const totalPlayers = Object.values(lineup).reduce((acc, curr) => acc + curr.length, 0);
    const [shouldRandomize, setShouldRandomize] = useState(false);
    const formationPositions = calculateFormationPositions(positions);
    const lastQueryRef = useRef(null);
    const lastResponseDataRef = useRef(null);
    const hasInitialized = useRef(false);

    useEffect(() => {
        if (positions && Object.keys(positions).length > 0) {
            const updatedPositions = Object.entries(positions).flatMap(([position, count]) =>
                Array(count).fill(position)
            );
            setFreePositionsOnField(updatedPositions);
        }
        
    }, [positions]); // Se ejecuta cada vez que positions cambia

    useEffect(() => {
        if (totalPlayers === totalRequiredPlayers) {
            setGameWon(true);
        }
    }, [totalPlayers, totalRequiredPlayers]);
    

    const randomizeTeams = useCallback(() => {
        if (!freePositionsOnField.length || !idPlayersOnField) {
            return; // Esperar a que los datos estén disponibles
        }

        let info = {};
        if (selectedTeam === 'allLPF') {
            info = {
                freePositions: freePositionsOnField,
                excluded: idPlayersOnField
            };
        } else {
            if (!selectedTeam) return;
            setTeamOne(selectedTeam);
            info = {
                team: selectedTeam,
                freePositions: freePositionsOnField,
                excluded: idPlayersOnField
            };
        }

        const infoString = JSON.stringify(info);
        // Si la consulta es idéntica a la anterior y ya tenemos datos, usar el último resultado
        if (lastQueryRef.current === infoString && lastResponseDataRef.current) {
            if (selectedTeam === 'allLPF') {
                if (lastResponseDataRef.current.qualifyingItems?.length > 0) {
                    const randomIndex = Math.floor(Math.random() * lastResponseDataRef.current.qualifyingItems.length);
                    const [t1, t2] = lastResponseDataRef.current.qualifyingItems[randomIndex];
                    setTeamOne(t1);
                    setTeamTwo(t2);
                }
            } else {
                if (lastResponseDataRef.current.qualifyingItems && Object.keys(lastResponseDataRef.current.qualifyingItems).length > 0) {
                    const keys = Object.keys(lastResponseDataRef.current.qualifyingItems);
                    const randomIndex = Math.floor(Math.random() * keys.length);
                    const t2 = keys[randomIndex];
                    setTeamTwo(t2);
                }
            }
            setHelpMessage('');
            return;
        } else {
            // Si la consulta cambió, la guardamos y hacemos la llamada al API.
            lastQueryRef.current = infoString;
            getPossibleSolutionsArg(info)
                .then((data) => {
                    lastResponseDataRef.current = data;
                    if (selectedTeam === 'allLPF') {
                        if (data?.qualifyingItems?.length > 0) {
                            const randomIndex = Math.floor(Math.random() * data.qualifyingItems.length);
                            const [t1, t2] = data.qualifyingItems[randomIndex];
                            setTeamOne(t1);
                            setTeamTwo(t2);
                        }
                    } else {
                        if (data?.qualifyingItems && Object.keys(data.qualifyingItems).length > 0) {
                            const keys = Object.keys(data.qualifyingItems);
                            const randomIndex = Math.floor(Math.random() * keys.length);
                            const t2 = keys[randomIndex];
                            setTeamTwo(t2);
                        }
                    }
                    setHelpMessage('');
                })
                .catch((error) => {
                    console.error("Error al obtener posibles soluciones:", error);
                });
        }
    }, [selectedTeam, freePositionsOnField, idPlayersOnField]);

    useEffect(() => {
        if (!freePositionsOnField.length || !idPlayersOnField) {
            return; // Esperar a que los datos estén disponibles
        } else {
            if (!hasInitialized.current) {
                hasInitialized.current = true;
                randomizeTeams();
            }
        }
    }, [randomizeTeams, freePositionsOnField, idPlayersOnField]);

    // useEffect que se ejecuta cada vez que freePositionsOnField cambia
    useEffect(() => {
        if (shouldRandomize) {
            randomizeTeams();
            setShouldRandomize(false); // reiniciamos el trigger para evitar llamadas repetidas
        }
    }, [freePositionsOnField, shouldRandomize, randomizeTeams]);

    const resetGame = () => {
        setLineup(initializeLineup());
        setError('');
        setGameWon(false);
        setSkipCount(3);
        setHelpMessage('');
        setSelectedPlayer(null);
        onResetGame();
    };

    const handlePlayerSelect = (player) => {
        if (Object.values(lineup).flat().some(p => p._id === player._id)) {
            setError('El jugador ya está en la formación');
            return;
        }
        if (!checkPlayer(player, teamOne) || !checkPlayer(player, teamTwo)) {
            setError('El jugador no cumple con los requisitos');
            return;
        }
        const availablePositions = player.specificPosition.filter(
            pos => positions[pos] !== undefined && (lineup[pos]?.length ?? 0) < positions[pos]
        );
        if (availablePositions.length === 0) {
            setError('No hay espacio para este jugador en la formación');
            return;
        }
        if (availablePositions.length === 1) {
            const selectedPos = availablePositions[0];
            setLineup(prev => ({
                ...prev,
                [selectedPos]: [...(prev[selectedPos] || []), player]
            }));
            setIdPlayersOnField(prev => [...prev, player._id]);
            setFreePositionsOnField(prev => {
                const index = prev.indexOf(selectedPos); // Encuentra el primer índice de la posición
                if (index === -1) return prev; // Si no se encuentra, no hacer cambios

                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            });
            setShouldRandomize(true);
        } else {
            setSelectedPlayer({ player, availablePositions });
        }
        setError('');
        setHelpMessage('');
    };

    const handleConfirmPosition = (position) => {
        setLineup(prev => ({
            ...prev,
            [position]: [...(prev[position] || []), selectedPlayer.player]
        }));
        setIdPlayersOnField(prev => [...prev, selectedPlayer.player._id]);
        setFreePositionsOnField(prev => {
            const index = prev.indexOf(position); // Encuentra el primer índice de la posición
            if (index === -1) return prev; // Si no se encuentra, no hacer cambios

            return [...prev.slice(0, index), ...prev.slice(index + 1)];
        });
        setSelectedPlayer(null);
        setShouldRandomize(true);
    };

    const handleSkip = () => {
        if (skipCount <= 0) return;
        setSkipCount(prev => prev - 1);
        randomizeTeams();
    };

    const handleHelp = async () => {
        const missingPositions = Object.keys(positions).filter(
            key => (lineup[key]?.length ?? 0) < positions[key]
        );

        if (missingPositions.length === 0) {
            setHelpMessage('La formación ya está completa.');
            return;
        }

        const currentQuery = {
            teamOne: teamOne.toLowerCase(),
            teamTwo: teamTwo.toLowerCase(),
            missingPositions: missingPositions.sort() // Ordenamos para comparar fácilmente
        };

        // Si ya hicimos la consulta, solo iteramos sobre los resultados
        if (
            lastQuery &&
            JSON.stringify(currentQuery) === JSON.stringify(lastQuery) &&
            currentSuggestions.length > 0
        ) {
            const suggestion = currentSuggestions[suggestionIndex % currentSuggestions.length];
            setHelpMessage(`${suggestion.nombre} puede ser agregado como ${suggestion.missingPos}`);
            setSuggestionIndex(suggestionIndex + 1);
            return;
        }

        // Si es una nueva consulta, reiniciamos los estados
        setLastQuery(currentQuery);
        setSuggestionIndex(0);

        try {
            const customLatinize = (text) =>
                text.split(/(ñ)/gi).map(part => part.toLowerCase() === 'ñ' ? part : latinize(part)).join('');

            const response = await fetch(
                `${backendURL}/players/searchByCriteria?item1=${customLatinize(teamOne)}&item2=${customLatinize(teamTwo)}&league=arg`
            );
            const data = await response.json();

            // Filtramos y asignamos la posición correcta
            const newSuggestions = [];
            missingPositions.forEach(pos => {
                const filteredPlayers = data
                    .filter(player => player.specificPosition.includes(pos))
                    .map(player => ({ ...player, missingPos: pos }));
                newSuggestions.push(...filteredPlayers);
            });

            if (newSuggestions.length === 0) {
                setHelpMessage('No se encontró sugerencia para ninguna posición faltante.');
                setCurrentSuggestions([]);
                return;
            }

            // Guardamos las sugerencias y mostramos la primera
            setCurrentSuggestions(newSuggestions);
            setHelpMessage(`${newSuggestions[0].nombre} puede ser agregado como ${newSuggestions[0].missingPos}`);
            setSuggestionIndex(1);
        } catch (err) {
            console.error(err);
            setHelpMessage('Error al obtener sugerencias.');
        }
    };

    // Función para descargar la formación como imagen
    const handleShareFormation = async () => {
        if (!fieldRef.current) return;
        try {
            const canvas = await html2canvas(fieldRef.current, { useCORS: true });
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('No se pudo crear el blob');
                    return;
                }
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'formation.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }, 'image/png');
        } catch (error) {
            console.error('Error al capturar la formación:', error);
        }
    };

    const teamImages = require.context('../../img', true, /\.jpg$/).keys()
        .reduce((acc, key) => ({
            ...acc,
            [key.replace('./', '').replace('.jpg', '')]: require(`../../img/${key.replace('./', '')}`)
        }), {});

    const getTeamImage = (teamName) => {
        const key = Object.keys(teamImages).find(key => key.endsWith(teamName));
        return key ? teamImages[key] : null;
    };


    return (
        <div className="player-combiner-container">
            <div className="team-banners">
                {teamOne && (
                    <img
                        src={getTeamImage(teamOne)}
                        alt={teamOne}
                        className="team-banner"
                    />
                )}
                {teamTwo && (
                    <img
                        src={getTeamImage(teamTwo)}
                        alt={teamTwo}
                        className="team-banner"
                    />
                )}
                <button
                    className="skip-btn"
                    onClick={handleSkip}
                    disabled={skipCount <= 0}
                    title={skipCount <= 0 ? "No te quedan skips" : ""}
                >
                    <span className="icon">⏭ </span>
                    {skipCount > 0 ? `Skip (${skipCount})` : "Sin Skips"}
                </button>
            </div>


            <div className="action-buttons">
                <button className="help-btn" onClick={handleHelp}>
                    <span className="icon">❓</span>
                    Ayuda
                </button>
                {helpMessage && <p className="help-message">{helpMessage}</p>}
            </div>

            <div className="field-player" ref={fieldRef}>
                <div className="center-circle"></div>
                <div className="half-line"></div>
                {Object.entries(formationPositions).map(([position, spots]) =>
                    spots.map((spot, index) => {
                        const isFilled = lineup[position] && lineup[position][index];
                        const isAvailableForSelected = selectedPlayer && selectedPlayer.availablePositions.includes(position) && !isFilled;
                        return (
                            <div
                                key={`${position}-${index}`}
                                className={`position-container ${isAvailableForSelected ? 'highlight-available' : ''}`}
                                style={{ top: `${spot.top}%`, left: `${spot.left}%` }}
                                onClick={isAvailableForSelected ? () => handleConfirmPosition(position) : undefined}
                            >
                                {isFilled ? (
                                    <div className="player-info">
                                        <div className="player-img-container">
                                            <img
                                                crossOrigin="anonymous"
                                                className="player-img"
                                                src={
                                                    lineup[position][index].img && lineup[position][index].img !== ''
                                                        ? lineup[position][index].img
                                                        : defaultPlayerImg
                                                }
                                                alt={lineup[position][index].nombre}
                                            />
                                        </div>
                                        <div className="player-name-container">
                                            <span className="player-name">{lineup[position][index].nombre}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="position-rectangle">
                                        <span className="position-label">{position}</span>
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}
            </div>

            {selectedPlayer && (
                <div className="selection-message">
                    <p>
                        Selecciona una posición para <strong>{selectedPlayer.player.nombre}</strong>
                    </p>
                    <p>(Haz clic en el rectángulo resaltado en verde)</p>
                </div>
            )}

            {!gameWon && !selectedPlayer && (
                <SearchPlayers onSelect={handlePlayerSelect} turn={1} player={1} league="arg" />
            )}

            {error && <div className="error-message">{error}</div>}

            {gameWon && (
                <div className="victory-screen">
                    <h2 className="victory-message">🏆 ¡Ganaste! 🏆</h2>
                    <div className="victory-buttons">
                        <button className="play-again-btn" onClick={resetGame}>
                            Jugar de Nuevo
                        </button>
                        <button className="share-btn" onClick={handleShareFormation}>
                            Descargar Imagen
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PlayerCombinerArg;