import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TimeLimitContext from '../../context/TimeLimitContext';
import Navbar from '../../components/Navbar/Navbar';
import Title from '../../components/Title/Title';
import HowToPlayModal from '../../modal/HowToPlayModal';
import WorldLogo from '../../img/WorldLogo.png';
import AfaLogo from '../../img/AfaLogo.png';
/* import MxLogo from '../../img/MxLogo.png';
import UruLogo from '../../img/UruLogo.png';
import EnglandLogo from '../../img/EnglandLogo.png' */
import Xicon from '../../img/x.png';
import './HomePage.css';

const HomePage = () => {

  const navigate = useNavigate();
  const { timeLimit, setTimeLimit } = useContext(TimeLimitContext);
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);
  const [showBuild11Modal, setShowBuild11Modal] = useState(false);
  const [showOtherModesModal, setShowOtherModesModal] = useState(false);
  const [showOtherModesModalOnline, setShowOtherModesModalOnline] = useState(false);
  const [showOtherModesModalBot, setShowOtherModesModalBot] = useState(false);
  const [showOtherModesModalPlayerFinder, setShowOtherModesModalPlayerFinder] = useState(false);

  return (
    <div className='container'>
      <Navbar />
      <Title />
      <div className="game-mode-actions">
        <button className='build11-button' onClick={() => setShowBuild11Modal(true)}>
          PLAY - ARMA TU 11
        </button>
        <button className="play-bot-button" onClick={() => setShowOtherModesModalBot(true)}>
          PLAY AGAINST A BOT
        </button>
        <div className="top-buttons">
          <button className="play-local-button" onClick={() => setShowOtherModesModal(true)}>
            PLAY LOCAL
          </button>
          <button className="play-online-button" onClick={() => setShowOtherModesModalOnline(true)}>
            PLAY ONLINE
          </button>
        </div>
        <button
          className="vote-league-button"
          onClick={() => navigate(`/ranking`)}
        >
          🔥 RANKING 🔥
        </button>
        {showBuild11Modal && (
          <div className="other-modes-modal">
            <div className="other-modes-content">
              <div className="other-modes-header">
                <h3>Choose league:</h3>
                <button
                  className="close-modal-button"
                  onClick={() => setShowBuild11Modal(false)}
                >
                  <img className="x-icon" src={Xicon} alt="X" />
                </button>
              </div>
              <div className="other-modes-form">
                <button className="football-button" onClick={() => navigate(`/squad`, { state: { mode: 'normal' } })}>
                  <img className="football-logo" src={WorldLogo} alt="WORLD LOGO" />
                  Normal Mode (World)
                </button>
                <button className="football-button" onClick={() => navigate(`/squad-arg`, { state: { mode: 'arg' } })}>
                  <img className="football-logo" src={AfaLogo} alt="AFA LOGO" />
                  Argentinian League
                </button>
              </div>
              <p className="coming-soon-text">Other leagues will be available soon.</p>
            </div>
          </div>
        )}
        {showOtherModesModal && (
          <div className="other-modes-modal">
            <div className="other-modes-content">
              <div className="other-modes-header">
                <h3>Choose league:</h3>
                <button
                  className="close-modal-button"
                  onClick={() => setShowOtherModesModal(false)}
                >
                  <img className="x-icon" src={Xicon} alt="X" />
                </button>
              </div>
              <div className="other-modes-form">
                <button className="football-button" onClick={() => navigate(`/local`, { state: { mode: 'normal' } })}>
                  <img className="football-logo" src={WorldLogo} alt="WORLD LOGO" />
                  Normal Mode (World)
                </button>
                {/*                   <button className="football-button" onClick={() => navigate(`/local`, { state: { mode: 'eng' } })}>
                    <img className="football-logo" src={EnglandLogo} alt="ENG LOGO" />
                    English League
                  </button> */}
                <button className="football-button" onClick={() => navigate(`/local`, { state: { mode: 'arg' } })}>
                  <img className="football-logo" src={AfaLogo} alt="AFA LOGO" />
                  Argentinian League
                </button>
                {/* <button className="football-button" onClick={() => navigate(`/local`, { state: { mode: 'mex' } })}>
                    <img className="football-logo" src={MxLogo} alt="MX LOGO" />
                    Mexican League
                  </button>
                  <button className="football-button" onClick={() => navigate(`/local`, { state: { mode: 'uru' } })}>
                    <img className="football-logo" src={UruLogo} alt="URU LOGO" />
                    Uruguayan League
                  </button> */}
              </div>
              <p className="coming-soon-text">Other leagues will be available soon.</p>
            </div>
          </div>
        )}
        {showOtherModesModalOnline && (
          <div className="other-modes-modal">
            <div className="other-modes-content">
              <div className="other-modes-header">
                <h3>Choose league:</h3>
                <button
                  className="close-modal-button"
                  onClick={() => setShowOtherModesModalOnline(false)}
                >
                  <img className="x-icon" src={Xicon} alt="X" />
                </button>
              </div>
              <div className="other-modes-form">
                <button className="football-button" onClick={() => navigate(`/room`, { state: { mode: 'normal' } })}>
                  <img className="football-logo" src={WorldLogo} alt="WORLD LOGO" />
                  Normal Mode (World)
                </button>
                {/*                   <button className="football-button" onClick={() => navigate(`/room`, { state: { mode: 'eng' } })}>
                    <img className="football-logo" src={EnglandLogo} alt="ENG LOGO" />
                    English League
                  </button> */}
                <button className="football-button" onClick={() => navigate(`/room`, { state: { mode: 'arg' } })}>
                  <img className="football-logo" src={AfaLogo} alt="AFA LOGO" />
                  Argentinian League
                </button>
                {/*                   <button className="football-button" onClick={() => navigate(`/room`, { state: { mode: 'mex' } })}>
                    <img className="football-logo" src={MxLogo} alt="MX LOGO" />
                    Mexican League
                  </button>
                  <button className="football-button" onClick={() => navigate(`/room`, { state: { mode: 'uru' } })}>
                    <img className="football-logo" src={UruLogo} alt="URU LOGO" />
                    Uruguayan League
                  </button> */}
              </div>
              <p className="coming-soon-text">Other leagues will be available soon.</p>
            </div>
          </div>
        )}
        {showOtherModesModalBot && (
          <div className="other-modes-modal">
            <div className="other-modes-content">
              <div className="other-modes-header">
                <h3>Choose league:</h3>
                <button
                  className="close-modal-button"
                  onClick={() => setShowOtherModesModalBot(false)}
                >
                  <img className="x-icon" src={Xicon} alt="X" />
                </button>
              </div>
              <div className="other-modes-form">
                <button className="football-button" onClick={() => navigate(`/solo-bot`, { state: { mode: 'normal' } })}>
                  <img className="football-logo" src={WorldLogo} alt="WORLD LOGO" />
                  Normal Mode (World)
                </button>
                {/*                   <button className="football-button" onClick={() => navigate(`/solo-bot`, { state: { mode: 'eng' } })}>
                    <img className="football-logo" src={EnglandLogo} alt="ENG LOGO" />
                    English League
                  </button> */}
                <button className="football-button" onClick={() => navigate(`/solo-bot-arg`, { state: { mode: 'arg' } })}>
                  <img className="football-logo" src={AfaLogo} alt="AFA LOGO" />
                  Argentinian League
                </button>
                {/*                   <button className="football-button" onClick={() => navigate(`/solo-bot`, { state: { mode: 'mex' } })}>
                    <img className="football-logo" src={MxLogo} alt="MX LOGO" />
                    Mexican League
                  </button>
                  <button className="football-button" onClick={() => navigate(`/solo-bot`, { state: { mode: 'uru' } })}>
                    <img className="football-logo" src={UruLogo} alt="URU LOGO" />
                    Uruguayan League
                  </button> */}
              </div>
              <p className="coming-soon-text">Other leagues will be available soon.</p>
            </div>
          </div>
        )}
        <button
          className="configure-room-button"
          onClick={() => setShowConfigureModal(true)}
        >
          CONFIGURE ROOM
        </button>
        {showConfigureModal && (
          <div className="join-room-modal">
            <div className="join-room-content">
              <div className="join-room-header">
                <h3>Configure Room</h3>
                <button
                  className="close-modal-button"
                  onClick={() => setShowConfigureModal(false)}
                >
                  <img className="x-icon" src={Xicon} alt="X" />
                </button>

              </div>
              <div className="join-room-form">
                <label htmlFor="timeLimit">TIME LIMIT PER TURN:</label>
                <select
                  value={timeLimit}
                  onChange={(e) => setTimeLimit(parseInt(e.target.value, 10))}
                >
                  <option value={20}>20 seconds</option>
                  <option value={30}>30 seconds</option>
                  <option value={60}>1 minute</option>
                  <option value={120}>2 minutes</option>
                  <option value={180}>3 minutes</option>
                  <option value={240}>4 minutes</option>
                  <option value={300}>5 minutes</option>
                </select>
              </div>
            </div>
          </div>
        )}
        <button
          className="find-players-button"
          onClick={
            () => setShowOtherModesModalPlayerFinder(true)
          }
        >
          PLAYER FINDER
        </button>
        {showOtherModesModalPlayerFinder && (
          <div className="other-modes-modal">
            <div className="other-modes-content">
              <div className="other-modes-header">
                <h3>Choose league:</h3>
                <button
                  className="close-modal-button"
                  onClick={() => setShowOtherModesModalPlayerFinder(false)}
                >
                  <img className="x-icon" src={Xicon} alt="X" />
                </button>
              </div>
              <div className="other-modes-form">
                <button className="football-button" onClick={() => navigate(`/player-finder`, { state: { mode: 'normal' } })}>
                  <img className="football-logo" src={WorldLogo} alt="WORLD LOGO" />
                  Normal Mode (World)
                </button>
                {/*                   <button className="football-button" onClick={() => navigate(`/solo-bot`, { state: { mode: 'eng' } })}>
                    <img className="football-logo" src={EnglandLogo} alt="ENG LOGO" />
                    English League
                  </button> */}
                <button className="football-button" onClick={() => navigate(`/player-finder-arg`, { state: { mode: 'arg' } })}>
                  <img className="football-logo" src={AfaLogo} alt="AFA LOGO" />
                  Argentinian League
                </button>
                {/*                   <button className="football-button" onClick={() => navigate(`/solo-bot`, { state: { mode: 'mex' } })}>
                    <img className="football-logo" src={MxLogo} alt="MX LOGO" />
                    Mexican League
                  </button>
                  <button className="football-button" onClick={() => navigate(`/solo-bot`, { state: { mode: 'uru' } })}>
                    <img className="football-logo" src={UruLogo} alt="URU LOGO" />
                    Uruguayan League
                  </button> */}
              </div>
            </div>
          </div>
        )}
        <div className="how-to-play-container">
          <button className="how-to-play-button" onClick={() => setShowHowToPlayModal(true)}>
            How to play?
          </button>
        </div>
        {showHowToPlayModal && <HowToPlayModal onClose={() => setShowHowToPlayModal(false)} />}
      </div>


      <div id="container-12af686062f23f6068597b46e4825913"></div>
    </div>
  );
}

export default HomePage;