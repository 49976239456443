import React, { useState, useEffect } from "react";
import './PlayerFinderPage.css'
import Navbar from '../../components/Navbar/Navbar';
import Title from "../../components/Title/Title";
import GoBackButton from '../../img/goback.png';
import { useNavigate } from 'react-router-dom';
import latinize from 'latinize';

const PlayerFinderPage = () => {
  const clubMappings = {
    "Ajax": "ajax",
    "Arsenal": "arsenal",
    "Aston Villa": "aston_villa",
    "Atalanta": "atalanta",
    "Atlético de Madrid": "atletico_madrid",
    "Barcelona": "barcelona",
    "Bayern Múnich": "bayern_munich",
    "Benfica": "benfica",
    "Boca Juniors": "boca-juniors",
    "Borussia Dortmund": "borussia_dortmund",
    "Brighton & Hove Albion": "brighton_and_hove_albion",
    "Celtic": "celtic",
    "Chelsea": "chelsea",
    "Everton": "everton",
    "Feyenoord": "feyenoord",
    "Fiorentina": "fiorentina",
    "Galatasaray": "galatasaray",
    "Inter": "inter",
    "Juventus": "juventus",
    "Lazio": "lazio",
    "Leeds United": "leeds_united",
    "Leicester": "leicester",
    "Liverpool": "liverpool",
    "Manchester City": "manchester_city",
    "Manchester United": "manchester_united",
    "Milan": "milan",
    "Mónaco": "monaco",
    "Napoli": "napoli",
    "Newcastle": "newcastle",
    "Nottingham Forest": "nottingham_forest",
    "Olympique Lyon": "olympique_lyon",
    "Olympique Marsella": "olympique_marseille",
    "Porto": "porto",
    "PSG": "psg",
    "PSV": "psv",
    "Real Madrid": "real_madrid",
    "River Plate": "river-plate",
    "Roma": "roma",
    "Sevilla": "sevilla",
    "Southampton": "southampton",
    "Sporting CP": "sporting_cp",
    "Tottenham Hotspur": "tottenham_hotspur",
    "Udinese": "udinese",
    "Valencia": "valencia",
    "Villarreal": "villarreal",
    "West Ham United": "west_ham_united",
    "Saudi League": "Saudi_League"
  };

  const initialSearchParams = {
    club1: null,
    club2: null,
    pais: null,
    campeonMundo: null,
    champions: null,
    balonOro: null,
    extras: null,
    europaLeague: null,
    eurocopa: null,
    dirigidoPor: null,
    copa_america: null,
    jjoo: null,
    maxTransferValue: null,
    goldenboy: null,
    bota_oro: null
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [results, setResults] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  const [countries, setCountries] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [extras] = useState(['african_player', 'concacaf_player']);

  // Cargar los datos de los JSON al montar el componente
  useEffect(() => {
    import('../../data/countries.json').then((data) => setCountries(Object.keys(data.default)));
    import('../../data/dts.json').then((data) => setDirectors(Object.keys(data.default)));
  }, []);

  const handleInputChange = (e, field) => {
    const value = e.target.value || null;
    const prevValue = searchParams[field];

    if (value && prevValue === null && selectedCount < 2) {
      setSelectedCount(selectedCount + 1);
    } else if (value === null && prevValue !== null) {
      setSelectedCount(selectedCount - 1);
    }

    setSearchParams({ ...searchParams, [field]: value });
  };

  const handleReset = () => {
    setSearchParams(initialSearchParams);
    setResults([]);
    setSelectedCount(0);
  };

  const customLatinize = (text) => {
    return text.split(/(ñ)/gi).map(part => {
      return part.toLowerCase() === 'ñ' ? part : latinize(part);
    }).join('');
  };

  const fetchPlayers = async () => {
    try {
      // Filtrar los parámetros seleccionados que no sean nulos
      const selectedParams = Object.keys(searchParams).filter(key => searchParams[key]);
      const item1Value = selectedParams[0] ? customLatinize(searchParams[selectedParams[0]].toLowerCase()) : '';
      const item2Value = selectedParams[1] ? customLatinize(searchParams[selectedParams[1]].toLowerCase()) : '';

      const response = await fetch(`${'https://footy-247087924341.us-central1.run.app'}/players/searchByCriteria?item1=${item1Value}&item2=${item2Value}&league=normal`);
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Error fetching players:', error);
      setResults([]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchPlayers();
  };

  return (
    <div className="container">
      <Navbar />
      <button
        className="go-home-button"
        onClick={() => navigate('/')}
      >
        <img className="goHomeIcon" src={GoBackButton} alt="Back" />
      </button>
      <Title />
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <div className="form-field">
              <label>Club 1:</label>
              <select value={searchParams.club1 || ""} onChange={e => handleInputChange(e, 'club1')} disabled={selectedCount === 2 && !searchParams.club1}>
                <option value="">--Select a club--</option>
                {Object.keys(clubMappings).map((club, index) => (
                  <option key={index} value={clubMappings[club]}>{club}</option>
                ))}
              </select>
            </div>
            <div className="form-field">
              <label>Club 2:</label>
              <select value={searchParams.club2 || ""} onChange={e => handleInputChange(e, 'club2')} disabled={selectedCount === 2 && !searchParams.club2}>
                <option value="">--Select a club--</option>
                {Object.keys(clubMappings).map((club, index) => (
                  <option key={index} value={clubMappings[club]}>{club}</option>
                ))}
              </select>
            </div>
            <div className="form-field">
              <label>Country:</label>
              <select value={searchParams.pais || ""} onChange={e => handleInputChange(e, 'pais')} disabled={selectedCount === 2 && !searchParams.pais}>
                <option value="">--Select a country--</option>
                {countries.map((country, index) => <option key={index} value={country}>{country}</option>)}
              </select>
            </div>
            <div className="form-field">
              <label>World Champion:</label>
              <select value={searchParams.campeonMundo || ""} onChange={e => handleInputChange(e, 'campeonMundo')} disabled={selectedCount === 2 && !searchParams.campeonMundo}>
                <option value="">--Select an option--</option>
                <option value="campeon_mundo">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Champions League Winner:</label>
              <select value={searchParams.champions || ""} onChange={e => handleInputChange(e, 'champions')} disabled={selectedCount === 2 && !searchParams.champions}>
                <option value="">--Select an option--</option>
                <option value="champions">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Ballon d'Or Winner: </label>
              <select value={searchParams.balonOro || ""} onChange={e => handleInputChange(e, 'balonOro')} disabled={selectedCount === 2 && !searchParams.balonOro}>
                <option value="">--Select an option--</option>
                <option value="balon_oro">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Extras:</label>
              <select value={searchParams.extras || ""} onChange={e => handleInputChange(e, 'extras')} disabled={selectedCount === 2 && !searchParams.extras}>
                <option value="">--Select--</option>
                {extras.map((extra, index) => <option key={index} value={extra}>{extra}</option>)}
              </select>
            </div>
            <div className="form-field">
              <label>Europa League Winner:</label>
              <select value={searchParams.europaLeague || ""} onChange={e => handleInputChange(e, 'europaLeague')} disabled={selectedCount === 2 && !searchParams.europaLeague}>
                <option value="">--Select an option--</option>
                <option value="europa_league">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Eurocopa Winner:</label>
              <select value={searchParams.eurocopa || ""} onChange={e => handleInputChange(e, 'eurocopa')} disabled={selectedCount === 2 && !searchParams.eurocopa}>
                <option value="">--Select an option--</option>
                <option value="eurocopa">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Copa America Winner:</label>
              <select value={searchParams.copa_america || ""} onChange={e => handleInputChange(e, 'copa_america')} disabled={selectedCount === 2 && !searchParams.copa_america}>
                <option value="">--Select an option--</option>
                <option value="copa_america">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Olympic Champion:</label>
              <select value={searchParams.jjoo || ""} onChange={e => handleInputChange(e, 'jjoo')} disabled={selectedCount === 2 && !searchParams.jjoo}>
                <option value="">--Select an option--</option>
                <option value="jjoo">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>European Golden Boot:</label>
              <select value={searchParams.bota_oro || ""} onChange={e => handleInputChange(e, 'bota_oro')} disabled={selectedCount === 2 && !searchParams.bota_oro}>
                <option value="">--Select an option--</option>
                <option value="bota_oro">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Golden Boy / Kopa:</label>
              <select value={searchParams.goldenboy || ""} onChange={e => handleInputChange(e, 'goldenboy')} disabled={selectedCount === 2 && !searchParams.goldenboy}>
                <option value="">--Select an option--</option>
                <option value="goldenboy">Yes</option>
              </select>
            </div>
            <div className="form-field">
              <label>Transfer Value [mill. €]:</label>
              <select value={searchParams.maxTransferValue || ""} onChange={e => handleInputChange(e, 'maxTransferValue')} disabled={selectedCount === 2 && !searchParams.maxTransferValue}>
                <option value="">--Select--</option>
                <option value="60transferValue">&gt;60</option>
              </select>
            </div>
            <div className="form-field">
              <label>Coached by / Player With:</label>
              <select value={searchParams.dirigidoPor || ""} onChange={e => handleInputChange(e, 'dirigidoPor')} disabled={selectedCount === 2 && !searchParams.dirigidoPor}>
                <option value="">--Select DT--</option>
                {directors.map((director, index) => <option key={index} value={director}>{director}</option>)}
              </select>
            </div>
          </div>
          <div className="form-field">
            <button className="searchButton" type="submit" disabled={selectedCount < 2}>Search</button>
            <button className="resetButton" type="button" onClick={handleReset}>Reset</button>
          </div>
        </form>
        <div className="results-container">
          {results.length > 0 ? (
            results.map((result, index) => {
              const year = result.fecha_nacimiento.split('.')[2]; // Extrae el año

              return (
                <div key={index} className="result-item">

                  <><img src={result.img_pais} alt={result.img_pais} width="20" height="15" /> {result.nombre} ({year})</>
                </div>
              );
            })
          ) : (
            <div className="result-item">
              No results found.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlayerFinderPage;


