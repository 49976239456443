import React, { useState, useEffect } from 'react';
import './FormationGenerator.css';

const formations = {
    '3-4-3': { GK: 1, CB: 3, CM: 2, RM: 1, LM: 1, RW: 1, LW:1, ST: 1 },
    '3-5-2': { GK: 1, CB: 3, CM: 3, RM: 1, LM: 1, ST: 2 },
    '4-3-3': { GK: 1, RB: 1, CB: 2, LB: 1, CM: 3, RW: 1, LW: 1, ST: 1 },
    '4-3-3 (A)': { GK: 1, RB: 1, CB: 2, LB: 1, CM: 2, CAM: 1, RW: 1, LW: 1, ST: 1 },
    '4-3-3 (B)': { GK: 1, RB: 1, CB: 2, LB: 1, CDM: 1, CM: 2, RW: 1, LW: 1, ST: 1 },
    '4-4-2': { GK: 1, RB: 1, CB: 2, LB: 1, RM: 1, CM: 2, LM: 1, ST: 2 },
    '4-5-1': { GK: 1, RB: 1, CB: 2, LB: 1, CDM: 1, CM: 2, RM: 1, LM: 1, ST: 1 },
    '4-5-1 (B)': { GK: 1, RB: 1, CB: 2, LB: 1, CM: 1, CAM: 2, RM: 1, LM: 1, ST: 1 },
    '4-1-2-1-2': { GK: 1, RB: 1, CB: 2, LB: 1, CDM: 1, CM: 2, CAM: 1, ST: 2 },
    '5-3-2': { GK: 1, CB: 3, LB: 1, RB: 1, CM: 3, ST: 2 },
    '5-4-1': { GK: 1, CB: 3, LB: 1, RB: 1, CM: 2, LM: 1, RM: 1, ST: 1 },
    '5-2-3': { GK: 1, CB: 3, LB: 1, RB: 1, CM: 2, LW: 1, RW: 1, ST: 1 },
    '5-2-1-2': { GK: 1, CB: 3, LB: 1, RB: 1, CM: 2, CAM: 1, ST: 2 },
};

const FormationGenerator = ({ onFormationGenerated }) => {
    const [formation, setFormation] = useState('');

    useEffect(() => {
        const randomFormation = Object.keys(formations)[Math.floor(Math.random() * Object.keys(formations).length)];
        setFormation(randomFormation);
        onFormationGenerated(randomFormation, formations[randomFormation]);
    }, [onFormationGenerated]);

    return (
        <div>
            <h2 className='formation-title'>Formación: {formation}</h2>
        </div>
    );
};

export default FormationGenerator;