import React, { useState, useEffect } from 'react';
import latinize from 'latinize';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import './PlayerSearch.css'

const backendURL = 'https://footy-247087924341.us-central1.run.app';

const PlayerSearch = ({ onSelect }) => {
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const debouncedFetchPlayers = debounce(async (value) => {
            try {
                const normalizedValue = latinize(value);
                const response = await fetch(`${backendURL}/players/arg/search?search=${normalizedValue}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const results = await response.json();
                setSearchResults(results);
            } catch (error) {
                console.error('Error fetching players:', error);
            }
        }, 500);

        if (searchValue.length >= 3) {
            debouncedFetchPlayers(searchValue);
        } else {
            setSearchResults([]);
        }

        return () => {
            debouncedFetchPlayers.cancel();
        };
    }, [searchValue]);

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchValue(value);
    };

    const handlePlayerSelect = (selectedOption) => {
        onSelect(selectedOption.value);
        setSearchValue('');
        setSearchResults([]);
    };

    const formatPlayerOptions = (players) => {
        return players.map((player) => ({
            value: player,
            label: (
                <div className="player-option">
                    <img src={player.img_pais} alt={player.posicion} width="20" height="15" />
                    <span>{` ${player.nombre} - ${player.posicion} - (${player.fecha_nacimiento})`}</span>
                </div>
            ),
        }));
    };

    return (
        <div className="search-players-container">
            <div className="search-input-container">
                <input
                    type="text"
                    placeholder="Buscar Jugador"
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="search-input"
                />
            </div>
            {searchValue.length >= 3 && searchResults.length > 0 && (
                <div className="search-select-container">
                    <Select
                        options={formatPlayerOptions(searchResults)}
                        onChange={handlePlayerSelect}
                        menuIsOpen
                        isSearchable={false}
                        classNamePrefix="react-select"
                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    />
                </div>
            )}
        </div>
    );
};

export default PlayerSearch;










