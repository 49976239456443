import { useEffect, useState, useMemo, useCallback } from "react";
import { getNextPlayerWithoutSpecificPosition, updatePlayerPosition, updatePlayer, skipPlayer, fetchPythonPositionLiveFutbol } from "../../services/api";
import "./UpdatePosition.css";

// Cargar imágenes de escudos
const teamImages = require.context('../../img/teams', false, /\.jpg$/).keys()
    .reduce((acc, key) => ({
        ...acc,
        [key.replace('./', '').replace('.jpg', '')]: require(`../../img/teams/${key.replace('./', '')}`)
    }), {});

const positions = ["GK", "CB", "LB", "RB", "CDM", "CM", "CAM", "RM", "LM", "RW", "LW", "ST"];

const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const calculateAge = (birthdate) => {
    if (!birthdate) return "Desconocida";
    const parts = birthdate.split(".");
    const birthDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
    if (isNaN(birthDate)) return "Desconocida";
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) age--;
    return age;
};

const parseBirthDate = (birthdateStr) => {
    const parts = birthdateStr.split(".");
    return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
};

const fetchSofascoreData = async (player) => {
    try {
        const playerNameQuery = encodeURIComponent(player.nombre);
        const searchUrl = `https://www.sofascore.com/api/v1/search/all?q=${playerNameQuery}`;
        const searchResponse = await fetch(searchUrl);
        const searchData = await searchResponse.json();

        const playerBirthDate = parseBirthDate(player.fecha_nacimiento);
        let matchedId = null;

        for (const result of searchData.results) {
            if (result.entity && result.entity.id && result.entity.name) {
                const detailsUrl = `https://www.sofascore.com/api/v1/player/${result.entity.id}`;
                const detailsResponse = await fetch(detailsUrl);
                const detailsData = await detailsResponse.json();
                if (detailsData.player && detailsData.player.dateOfBirthTimestamp) {
                    const candidateBirthDate = new Date(detailsData.player.dateOfBirthTimestamp * 1000);
                    if (
                        candidateBirthDate.getFullYear() === playerBirthDate.getFullYear() &&
                        candidateBirthDate.getMonth() === playerBirthDate.getMonth() &&
                        candidateBirthDate.getDate() === playerBirthDate.getDate()
                    ) {
                        matchedId = result.entity.id;
                        break;
                    }
                }
            }
        }

        if (!matchedId) return null;

        const charUrl = `https://www.sofascore.com/api/v1/player/${matchedId}/characteristics`;
        const charResponse = await fetch(charUrl);
        const charData = await charResponse.json();
        if (!charData.positions) return null;

        const conversionMap = {
            "ST": "ST",
            "LW": "LW",
            "RW": "RW",
            "AM": "CAM",
            "ML": "LM",
            "MC": "CM",
            "MR": "RM",
            "DM": "CDM",
            "DL": "LB",
            "DC": "CB",
            "DR": "RB",
            "GK": "GK"
        };
        const mappedPositions = charData.positions.map(pos => conversionMap[pos] || pos);
        const sofascoreImageUrl = `https://img.sofascore.com/api/v1/player/${matchedId}/image`;
        return { positions: mappedPositions, imageUrl: sofascoreImageUrl };
    } catch (error) {
        console.error("Error al obtener datos de Sofascore:", error);
        return null;
    }
};

const UpdatePosition = () => {
    const [player, setPlayer] = useState(null);
    const [selectedPositions, setSelectedPositions] = useState([]);
    const [newImageUrl, setNewImageUrl] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [autoMode, setAutoMode] = useState(false);

    // Función para obtener el siguiente jugador
    const fetchNextPlayer = useCallback(async () => {
        try {
            const data = await getNextPlayerWithoutSpecificPosition();
            console.log("Jugador obtenido:", data);
            setPlayer(data);
            setSelectedPositions([]);
            setNewImageUrl("");
            setErrorMsg("");
        } catch (error) {
            console.error("Error al obtener el jugador:", error);
            setPlayer(null);
        }
    }, []);

    useEffect(() => {
        fetchNextPlayer();
    }, [fetchNextPlayer]);

    const saveImage = async () => {
        if (!player || !newImageUrl.trim()) return;
        try {
            console.log("URL de la nueva imagen:", newImageUrl);
            await updatePlayer({ ...player, img: newImageUrl });
            setPlayer((prev) => ({ ...prev, img: newImageUrl }));
        } catch (error) {
            console.error("Error al actualizar la imagen:", error);
        }
    };

    const togglePosition = (pos) => {
        setSelectedPositions((prev) =>
            prev.includes(pos) ? prev.filter((p) => p !== pos) : [...prev, pos]
        );
    };

    // Envolver savePosition en useCallback para estabilidad de dependencias
    const savePosition = useCallback(async () => {
        if (!player || selectedPositions.length === 0) return;
        try {
            await updatePlayerPosition(player._id, selectedPositions);
            fetchNextPlayer();
        } catch (error) {
            console.error("Error al actualizar la posición:", error);
        }
    }, [player, selectedPositions, fetchNextPlayer]);

    // Envolver skipPlayerAct en useCallback
    const skipPlayerAct = useCallback((id) => async () => {
        if (!player) return;
        try {
            await skipPlayer(id);
            fetchNextPlayer();
        } catch (error) {
            console.error("Error al saltar el jugador:", error);
        }
    }, [player, fetchNextPlayer]);

    const autoCompletePositions = useCallback(async () => {
        if (!player) return;
        let fetchedData = await fetchSofascoreData(player);
        // Si Sofascore no devuelve posiciones, se intenta con el script Python
        if (!fetchedData || !fetchedData.positions || fetchedData.positions.length === 0) {
            console.log("Sofascore no devolvió datos, intentando con Python...");
            const pythonPositions = await fetchPythonPositionLiveFutbol(player.nombre, player.fecha_nacimiento);
            if (pythonPositions && Array.isArray(pythonPositions) && pythonPositions.length > 0) {
                setSelectedPositions(pythonPositions);
            } else {
                setErrorMsg("No se encontraron coincidencias en Sofascore ni en el script Python para este jugador.");
                /* await skipPlayerAct(player._id)(); */
                return;
            }
        } else {
            setSelectedPositions(fetchedData.positions);
            // Verificar imagen: si el jugador no tiene imagen y se obtuvo URL desde Sofascore
            if (!player.img && fetchedData.imageUrl) {
                try {
                    const imgResponse = await fetch(fetchedData.imageUrl, { method: "HEAD" });
                    if (imgResponse.ok && imgResponse.status !== 404) {
                        await updatePlayer({ ...player, img: fetchedData.imageUrl });
                        setPlayer((prev) => ({ ...prev, img: fetchedData.imageUrl }));
                    } else {
                        console.warn("La imagen de Sofascore no existe (404).");
                    }
                } catch (error) {
                    console.error("Error al verificar la imagen de Sofascore:", error);
                }
            }
        }
        // Si se obtuvieron posiciones (ya sea desde Sofascore o Python), guardar automáticamente
        if ((fetchedData && fetchedData.positions && fetchedData.positions.length > 0) ||
            (selectedPositions && selectedPositions.length > 0)) {
            await savePosition();
        } else {
            /* await skipPlayerAct(player._id)(); */
        }
    }, [player, savePosition, /* skipPlayerAct, */ selectedPositions]);



    // Modo automático: se ejecuta autoCompletePositions en cada cambio de jugador o autoMode
    useEffect(() => {
        if (autoMode) {
            const timer = setTimeout(() => {
                autoCompletePositions();
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [autoMode, player, autoCompletePositions]);

    const toggleAutoMode = () => {
        setAutoMode(!autoMode);
    };

    const formattedPlayerName = useMemo(() => {
        if (!player || !player.nombre) return "";
        return removeAccents(player.nombre);
    }, [player]);

    const playerNameForBeSoccer = formattedPlayerName ? formattedPlayerName.toLowerCase().replace(/\s+/g, "-") : "";
    const playerNameForTransfermarkt = formattedPlayerName ? encodeURIComponent(formattedPlayerName) : "";

    if (!player) return <p>No hay más jugadores pendientes.</p>;

    return (
        <div className="update-position-container">
            <h2 className="title">
                Asignar posición a {player.nombre} <img src={player.img_pais} alt="País" />
            </h2>
            <h3 className="s-title">
                {player.fecha_nacimiento} ({calculateAge(player.fecha_nacimiento)} años)
            </h3>

            {errorMsg && <p className="error-msg">{errorMsg}</p>}

            {player.clubes && player.clubes.length > 0 && (
                <div className="player-clubs">
                    {player.clubes.map((club, index) => (
                        <img
                            key={index}
                            src={teamImages[club] || "default-image.jpg"}
                            alt="Escudo del club"
                            className="club-logo"
                        />
                    ))}
                </div>
            )}

            {player.img ? (
                <img src={player.img} alt={player.nombre} className="player-image" />
            ) : (
                <div className="image-upload">
                    <p>El jugador no tiene imagen, ingresa una URL:</p>
                    <input
                        type="text"
                        value={newImageUrl}
                        onChange={(e) => setNewImageUrl(e.target.value)}
                        placeholder="URL de la imagen"
                    />
                    <button onClick={saveImage} disabled={!newImageUrl.trim()}>
                        Guardar imagen
                    </button>
                </div>
            )}

            <p className="general-position">Posición General: {player.posicion}</p>

            <div className="player-links">
                <a
                    href={`https://es.besoccer.com/buscar/${playerNameForBeSoccer}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button besoccer"
                >
                    Ver en BeSoccer
                </a>
                <a
                    href={`https://www.transfermarkt.com.ar/schnellsuche/ergebnis/schnellsuche?query=${playerNameForTransfermarkt}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button transfermarkt"
                >
                    Buscar en Transfermarkt
                </a>
            </div>

            <button className="auto-complete-button" onClick={autoCompletePositions}>
                Autocompletar Posiciones
            </button>

            <div className="positions-grid">
                {positions.map((pos) => (
                    <button
                        key={pos}
                        className={`position-button ${selectedPositions.includes(pos) ? "selected" : ""}`}
                        onClick={() => togglePosition(pos)}
                    >
                        {pos}
                    </button>
                ))}
            </div>

            <button className="save-button" onClick={savePosition} disabled={selectedPositions.length === 0}>
                Guardar y siguiente
            </button>

            <button className="skip-button-player" onClick={skipPlayerAct(player._id)}>
                Skip
            </button>

            <button
                className={`auto-mode-button ${autoMode ? "active" : ""}`}
                onClick={toggleAutoMode}
            >
                {autoMode ? "Detener Auto" : "Auto"}
            </button>

        </div>
    );
};

export default UpdatePosition;
