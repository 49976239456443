import React, { useState } from "react";
import AddPlayerForm from "../../components/AddPlayerForm/AddPlayerForm";
import AddPlayerFormArg from "../../components/AddPlayerForm/AddPlayerFormArg";
import EditPlayerForm from "../../components/EditPlayerForm/EditPlayerForm";
import EditPlayerFormArg from "../../components/EditPlayerForm/EditPlayerFormArg";
import UpdatePosition from "../../components/UpdatePosition/UpdatePosition"; // Importar el nuevo componente
import "./AdminPage.css"; // Importa el CSS aquí

const AdminPage = () => {
    const [isAdding, setIsAdding] = useState(true);
    const [isArgentina, setIsArgentina] = useState(false);
    const [isUpdatingPosition, setIsUpdatingPosition] = useState(false); // Nuevo estado para UpdatePosition

    const getTitle = () => {
        if (isUpdatingPosition) return "Asignar Posiciones a Jugadores";
        if (isAdding && !isArgentina) return "Agregar Jugador";
        if (!isAdding && !isArgentina) return "Modificar Jugador";
        if (isAdding && isArgentina) return "Agregar Jugador (Argentina)";
        if (!isAdding && isArgentina) return "Modificar Jugador (Argentina)";
        return "";
    };

    return (
        <div className="app-container">
            <div className="button-group">
                <button 
                    onClick={() => { setIsAdding(true); setIsArgentina(false); setIsUpdatingPosition(false); }} 
                    className={isAdding && !isArgentina && !isUpdatingPosition ? "active" : ""}
                >
                    Agregar Jugador
                </button>
                <button 
                    onClick={() => { setIsAdding(false); setIsArgentina(false); setIsUpdatingPosition(false); }} 
                    className={!isAdding && !isArgentina && !isUpdatingPosition ? "active" : ""}
                >
                    Modificar Jugador
                </button>
                <button 
                    onClick={() => { setIsAdding(true); setIsArgentina(true); setIsUpdatingPosition(false); }} 
                    className={isAdding && isArgentina && !isUpdatingPosition ? "active argentina" : "argentina"}
                >
                    Agregar Jugador (Argentina)
                </button>
                <button 
                    onClick={() => { setIsAdding(false); setIsArgentina(true); setIsUpdatingPosition(false); }} 
                    className={!isAdding && isArgentina && !isUpdatingPosition ? "active argentina" : "argentina"}
                >
                    Modificar Jugador (Argentina)
                </button>
                <button 
                    onClick={() => { setIsUpdatingPosition(true); setIsAdding(false); setIsArgentina(false); }} 
                    className={isUpdatingPosition ? "active position-update" : "position-update"}
                >
                    Asignar Posiciones
                </button>
            </div>
            
            <h1 className="page-title">{getTitle()}</h1>

            {isUpdatingPosition ? (
                <UpdatePosition /> // Mostrar el nuevo componente cuando isUpdatingPosition sea true
            ) : (
                !isArgentina ? (
                    isAdding ? <AddPlayerForm /> : <EditPlayerForm />
                ) : (
                    isAdding ? <AddPlayerFormArg /> : <EditPlayerFormArg />
                )
            )}
        </div>
    );
};

export default AdminPage;
