import React, { useState } from 'react';
import clubsArg from '../../data/clubsArg.json';
import './TeamSelector.css'; // Archivo CSS para estilos

const TeamSelector = ({ onSelect }) => {
    const [selectedTeam, setSelectedTeam] = useState('');

    // Cargar las imágenes de los escudos usando require.context
    const loadTeamImages = () => {
        const context = require.context('../../img/teams', false, /\.jpg$/);
        const images = {};
        context.keys().forEach((key) => {
            const teamName = key.replace('./', '').replace('.jpg', '');
            images[teamName] = context(key);
        });
        return images;
    };

    const teamImages = loadTeamImages();

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
        onSelect(team);
    };

    return (
        <div className="team-selector-container">
            <h2 className='team-title'>Arma tu 11! - Selecciona un equipo</h2>
            <div className="team-grid">
                {/* Opción para seleccionar ambos equipos aleatoriamente */}
                <div
                    className={`team-logo ${selectedTeam === 'allLPF' ? 'selected' : ''}`}
                    onClick={() => handleTeamSelect('allLPF')}
                >
                    <img
                        src={teamImages['allLPF']} // Asegúrate de que la imagen allLPF.jpg está en la carpeta teams
                        alt="All LPF"
                        className="team-logo-img"
                        onError={(e) => {
                            e.target.style.display = 'none';
                        }}
                    />
                </div>
                {Object.keys(clubsArg).map((team) => (
                    <div
                        key={team}
                        className={`team-logo ${selectedTeam === team ? 'selected' : ''}`}
                        onClick={() => handleTeamSelect(team)}
                    >
                        <img
                            src={teamImages[team]} // Usar la imagen cargada dinámicamente
                            alt={team}
                            className="team-logo-img"
                            onError={(e) => {
                                e.target.style.display = 'none'; // Ocultar la imagen si no se carga
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamSelector;
