import React, { useState } from 'react';
import TeamSelector from '../../components/TeamSelector/TeamSelector';
import FormationGenerator from '../../components/FormationGenerator/FormationGenerator';
import PlayerCombiner from '../../components/PlayerCombiner/PlayerCombiner';
import clubs from '../../data/clubs.json';
import './SquadPage.css'; // Archivo CSS para estilos

const SquadPage = () => {
    const [selectedTeam, setSelectedTeam] = useState('');
    const [randomTeam, setRandomTeam] = useState('');
    const [formation, setFormation] = useState('');
    const [positions, setPositions] = useState({});

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
        // Generar un equipo aleatorio
        const otherTeams = Object.keys(clubs).filter((t) => t !== team);
        const randomTeam = otherTeams[Math.floor(Math.random() * otherTeams.length)];
        setRandomTeam(randomTeam);
    };

    const handleFormationGenerated = (formation, positions) => {
        setFormation(formation);
        setPositions(positions);
    };

    const [resetKey, setResetKey] = useState(0);

    const handleResetGame = () => {
        console.log("Ejecutando handleResetGame...");
        setSelectedTeam('');
        setFormation('');
        setPositions({});
        setResetKey(prev => prev + 1); // 🔄 Forzar re-render
    };



    return (
        <div className="team-page">
            {!selectedTeam ? (
                <TeamSelector key={resetKey} onSelect={handleTeamSelect} />
            ) : (
                <div className="game-section">
                    <FormationGenerator onFormationGenerated={handleFormationGenerated} />
                    {formation && (
                        <PlayerCombiner
                            selectedTeam={selectedTeam}
                            randomTeam={randomTeam}
                            positions={positions}
                            onResetGame={handleResetGame}
                        />
                    )}
                </div>
            )}

        </div>
    );
};

export default SquadPage;