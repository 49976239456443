import { toast } from 'react-toastify';
const API_URL = 'https://footy-247087924341.us-central1.run.app';
let tokenP = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

async function fetchWithToken(endpoint, options = {}) {
    let token = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    if (!token) {
        console.log('No token found');

        return;
    }

    const fetchData = async () => {
        let response;

        try {
            response = await fetch(`${API_URL}${endpoint}`, {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (response.status === 401) { // Token expired
                try {
                    token = await refreshToken();
                    localStorage.setItem('authUserData', JSON.stringify({ token })); // Update localStorage
                    response = await fetch(`${API_URL}${endpoint}`, {
                        ...options,
                        headers: {
                            ...options.headers,
                            'Authorization': `Bearer ${token}`
                        },
                        credentials: 'include'
                    });
                } catch (refreshError) {
                    console.error('Failed to refresh token:', refreshError);
                    toast.error('Sesión expirada. Por favor inicia sesión nuevamente.');
                    throw new Error('Failed to refresh token.');
                }
            }

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(errorMessage || 'Error en la solicitud');
            }

            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                return response.json();
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error in fetchWithToken:', error);
            throw error;
        }
    };

    return fetchData();
}


async function fetchWithTokenPlayer(endpoint, options = {}) {
    let token = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    if (!token) {
        console.log('No token found');

        return;
    }

    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });

        if (response.status === 401) { // Unauthorized, token might be expired
            token = await refreshToken();
            // Retry the original request with the new token
            return fetch(`${API_URL}${endpoint}`, {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
        }

        // Verificar que la respuesta es válida
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error in the request');
        }

        return response; // Devolver el objeto Response directamente

    } catch (error) {
        console.error('Fetch error:', error);
        throw error; // Re-lanzar el error para manejarlo en el lugar adecuado
    }
}

async function fetchNoUser(endpoint, options = {}) {
    const response = await fetch(`${API_URL}${endpoint}`, {
        ...options,
        credentials: 'include'
    });

    const data = await response.json();

    if (!response.ok) {

        const errorMessage = data.message || 'Error en la solicitud';
        throw new Error(errorMessage);
    }

    return data;
}

async function refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        toast.error('No refresh token found. Please log in again.');
        throw new Error('No refresh token found.');
    }

    const response = await fetch(`${API_URL}/auth/refreshToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`
        }
    });

    if (response.status === 401) { // Token expired
        localStorage.removeItem('authUserData');
        localStorage.removeItem('refreshToken');
        toast.error('Session expired. Please log in again.');
        // Redirige al usuario a la página de inicio de sesión
        window.location.href = '/login';
        throw new Error('Refresh token expired.');
    }

    if (!response.ok) {
        throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    localStorage.setItem('authUserData', JSON.stringify({ token: data.token }));
    return data.token;
}


// Usuarios
export const loginUser = (username, password) => {
    return fetchNoUser('/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    });
}

export const registerUser = (userData) => {
    return fetchNoUser('/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    });
}

export const registerUserWithoutConfirmation = (userData) => {
    return fetchNoUser('/auth/register-without-confirmation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    });
}

export const confirmUser = (confirmationCode) => {
    return fetchNoUser('/auth/confirm-registration', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ confirmationCode })
    });
}

export const logoutUser = () => {
    return fetchNoUser('/auth/logout', {
        method: 'POST'
    });
}

export const getUser = () => {
    return fetchWithToken('/user', {
        method: 'GET'
    });
}

export const getAllUsers = () => {
    return fetchNoUser('/user/all', {
        method: 'GET'
    });
}

// Game

export const generateGameToken = () => {
    const token = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    return fetchWithToken('/game/generateToken', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

export const addPoints = (token, points, difficulty, league, streak) => {

    if (!token) {
        return;
    }

    return fetchWithToken('/game/addPoints', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenP}`
        },
        body: JSON.stringify({ token, points, difficulty, league, streak })
    });
}

export const removePoints = (token, difficulty, league) => {
    const tokenP = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    if (!token) {
        return;
    }

    return fetchWithToken('/game/removePoints', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenP}`
        },
        body: JSON.stringify({ token, difficulty, league })
    });
}

// Players

export const addPlayer = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer('/players', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData)
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al agregar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al agregar el jugador:', error);
        throw error;
    }
};

export const addPlayerArg = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer('/players/arg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData)
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al agregar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al agregar el jugador:', error);
        throw error;
    }
}

export const updatePlayer = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer(`/players/${playerData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData),
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al actualizar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al actualizar el jugador:', error);
        throw error;
    }
}

export const updatePlayerArg = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer(`/players/arg/${playerData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData),
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            console.log(response.json);

            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al actualizar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al actualizar el jugador:', error);
        throw error;
    }
};

export const deletePlayer = (playerId) => {
    return fetchWithTokenPlayer(`/players/${playerId}`, {
        method: 'DELETE'
    });
}

export const deletePlayerArg = (playerId) => {
    return fetchWithTokenPlayer(`/players/arg/${playerId}`, {
        method: 'DELETE'
    });
}

export const getNextPlayerWithoutSpecificPosition = async () => {
    try {
        const response = await fetchWithTokenPlayer(`/players/arg/nextPlayer`, { method: 'GET' });

        if (!response.ok) {
            throw new Error(`Error al obtener el jugador: ${response.status}`);
        }

        const data = await response.json(); // 🔥 Aquí convertimos la respuesta en JSON
        console.log("Jugador obtenido:", data); // Ahora debería mostrar el objeto correctamente
        return data;
    } catch (error) {
        console.error("Error al obtener el jugador:", error);
        return null; // Retornamos null en caso de error
    }
};

export const skipPlayer = async (playerId) => {
    try {
        const response = await fetchWithTokenPlayer(`/players/arg/${playerId}/skip`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Error al saltar el jugador');
        }

        const responseData = await response.json();
        return responseData.updatedPlayer;
    } catch (error) {
        console.error('Error al saltar el jugador:', error);
        throw error;
    }
}

export const updatePlayerPosition = async (playerId, position) => {
    try {
        const response = await fetchWithTokenPlayer(`/players/arg/${playerId}/updatePosition`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ specificPosition: position }), // ✅ Cambio aquí
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al actualizar el jugador');
        }

        console.log("Jugador actualizado con éxito:", responseData.updatedPlayer);
        return responseData.updatedPlayer;
    } catch (error) {
        console.error('Error al actualizar el jugador:', error);
        throw error;
    }
};

export const fetchPythonPositionLiveFutbol = async (name, birthdate) => {
    try {
        const response = await fetchNoUser(
            `/sp/livefutbol?name=${encodeURIComponent(name)}&birthdate=${encodeURIComponent(birthdate)}`,
            { method: 'GET' }
        );

        if (!response.positions) {
            return null;
        }

        return response;
    }
    catch (error) {
        console.error('Error al obtener la posición del jugador:', error);
        throw error;
    }
};

export const fetchPythonPositionBeSoccer = async (name, birthdate) => {
    try {
        const response = await fetchNoUser(
            `/sp/besoccer?name=${encodeURIComponent(name)}&birthdate=${encodeURIComponent(birthdate)}`,
            { method: 'GET' }
        );

        if (!response.positions && !response.img) {
            return null;
        }

        return response;
    }
    catch (error) {
        console.error('Error al obtener la posición del jugador:', error);
        throw error;
    }
};

export const getPossibleSolutions = async (info) => {
    try {
        // Construir la URL dinámicamente sin incluir `team` si no existe
        let url = `/players/possibleSolutions?freePositions=${info.freePositions}&excluded=${info.excluded}`;
        
        if (info.team) {
            url += `&team=${info.team}`;
        }

        const response = await fetchNoUser(url, { method: 'GET' });

        if (!response.qualifyingItems) {
            return null;
        }
        return response;
    } catch (error) {
        console.error('Error al obtener las posibles soluciones:', error);
        throw error;
    }
};

export const getPossibleSolutionsArg = async (info) => {
    try {
        // Construir la URL dinámicamente sin incluir `team` si no existe
        let url = `/players/arg/possibleSolutions?freePositions=${info.freePositions}&excluded=${info.excluded}`;
        
        if (info.team) {
            url += `&team=${info.team}`;
        }

        const response = await fetchNoUser(url, { method: 'GET' });

        if (!response.qualifyingItems) {
            return null;
        }
        return response;
    } catch (error) {
        console.error('Error al obtener las posibles soluciones:', error);
        throw error;
    }
}


