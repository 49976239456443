import React, { useState } from 'react';
import PlayerSearch from './PlayerSearchArg';
import { ToastContainer, toast } from 'react-toastify';
import { updatePlayerArg, deletePlayerArg } from '../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import clubs from '../../data/clubsArg.json';
import countries from '../../data/country.json';
import coaches from '../../data/dtsArg.json';
import positions from '../../data/positions.json';
import specificPositions from '../../data/specificPosition.json';
import '../AddPlayerForm/AddPlayerForm.css';

const initialPlayerArg = {
    name: '',
    country: '',
    position: '',
    birthDate: '',
    clubs: [],
    image: '',
    countryFlag: '',
    argentinaCup: false,
    copaAmerica: false,
    worldChampion: false,
    coaches: [],
    specificPositions: []
};

const EditPlayerFormArg = () => {
    const [player, setPlayer] = useState(initialPlayerArg);
    const [originalPlayer, setOriginalPlayer] = useState(null);

    const mapPlayerToEnglish = (selectedPlayer) => ({
        id: selectedPlayer._id,
        name: selectedPlayer.nombre || '',
        country: Object.entries(countries).find(([code, name]) => name === selectedPlayer.pais)?.[0] || '',
        position: selectedPlayer.posicion || '',
        birthDate: selectedPlayer.fecha_nacimiento.split('.').reverse().join('-'),
        clubs: selectedPlayer.clubes || [],
        image: selectedPlayer.img || '',
        countryFlag: selectedPlayer.img_pais || '',
        argentinaCup: selectedPlayer.copa_argentina || false,
        copaAmerica: selectedPlayer.copaAmerica || false,
        worldChampion: selectedPlayer.campeon_mundo || false,
        coaches: selectedPlayer.dirigidoPor || [],
        specificPositions: selectedPlayer.specificPosition || []
    });

    const handleSelect = (selectedPlayer) => {
        const mappedPlayer = mapPlayerToEnglish(selectedPlayer);
        setPlayer(mappedPlayer);
        setOriginalPlayer(mappedPlayer);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlayer(prev => ({ ...prev, [name]: value }));

        if (name === 'country') {
            setPlayer(prev => ({ ...prev, countryFlag: getCountryFlag(value) }));
        }
    };

    const handleMultiSelectChange = (e, fieldName) => {
        const { value } = e.target;
        if (!value) return;
        setPlayer(prev => ({
            ...prev,
            [fieldName]: [...new Set([...prev[fieldName], value])]
        }));
    };

    const handleRemoveSelected = (item, fieldName) => {
        setPlayer(prev => ({
            ...prev,
            [fieldName]: prev[fieldName].filter(i => i !== item)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Filtramos los campos que tengan valor (igual que antes)
        const filteredPlayer = Object.keys(player).reduce((acc, key) => {
            if (
                player[key] !== '' &&
                player[key] !== false &&
                !(Array.isArray(player[key]) && player[key].length === 0)
            ) {
                acc[key] = player[key];
            }
            return acc;
        }, {});

        // Convertir el código de país a nombre completo usando el objeto countries
        if (filteredPlayer.country) {
            // Si tus claves en countries están en minúsculas, convierto el código a minúsculas
            const countryCode = filteredPlayer.country.toLowerCase();
            filteredPlayer.country = countries[countryCode] || filteredPlayer.country;
        }
        // Formatear la fecha a dd.mm.yyyy
        if (filteredPlayer.birthDate) {
            const [year, month, day] = filteredPlayer.birthDate.split('-');
            filteredPlayer.birthDate = `${day}.${month}.${year}`;
        }

        // Mapeo de las propiedades internas (en inglés) a las claves que espera la API (en español)
        const payload = {
            _id: filteredPlayer.id,
            nombre: filteredPlayer.name,
            nombre_lowercase: filteredPlayer.name ? filteredPlayer.name.toLowerCase() : "",
            pais: filteredPlayer.country,
            posicion: filteredPlayer.position,
            fecha_nacimiento: filteredPlayer.birthDate,
            clubes: filteredPlayer.clubs,
            img_pais: filteredPlayer.countryFlag,
            img: filteredPlayer.image,
            copa_argentina: Boolean(filteredPlayer.argentinaCup),
            copaAmerica: Boolean(filteredPlayer.copaAmerica),
            campeon_mundo: Boolean(filteredPlayer.worldChampion),
            dirigidoPor: filteredPlayer.coaches,
            specificPosition: filteredPlayer.specificPositions
        };

        try {
            await updatePlayerArg(payload);
            toast.success('Jugador actualizado con éxito');
            handleAfterSubmit();
        }
        catch (error) {
            console.error('Error updating player:', error);
            toast.error('Error al actualizar el jugador');
        }
    };


    const handleCancel = () => {
        setPlayer(initialPlayerArg);
        setOriginalPlayer(null);
    };

    const handleAfterSubmit = () => {
        setPlayer(initialPlayerArg);
        setOriginalPlayer(null);
    };

    const handleDelete = async () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este jugador?')) {
            try {
                const response = await deletePlayerArg(player.id);
                if (!response.ok) {
                    throw new Error('Error al eliminar el jugador');
                }
                toast.success('Jugador eliminado con éxito');
                handleCancel();
            } catch (error) {
                console.error('Error deleting player:', error);
                toast.error('Error al eliminar el jugador');
            }
        }
    };

    const getCountryFlag = (countryCode) => {
        return `https://flagcdn.com/16x12/${countryCode}.png`;
    };

    const SelectField = ({ label, name, options, value, onChange, multiple, placeholder, selectedItems, onRemove }) => (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <select
                name={name}
                id={name}
                value={value}
                onChange={onChange}
                className="form-select"
            >
                <option value="">{placeholder}</option>
                {options.map(opt => (
                    <option key={opt.value} value={opt.value}>{opt.label}</option>
                ))}
            </select>
            {multiple && selectedItems && (
                <div className="selected-items">
                    {selectedItems.map((item, idx) => (
                        <div key={idx} className="selected-item">
                            {item} <button type="button" onClick={() => onRemove(item, name)}>x</button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    // Componente para checkbox customizado
    const CheckToggle = ({ label, name, value, onChange }) => {
        const handleToggle = () => {
            onChange({ target: { name, value: !value } });
        };

        return (
            <div className="check-toggle">
                <label htmlFor={name} className="check-label">{label}</label>
                <div className="checkbox-container">
                    <input
                        type="checkbox"
                        id={name}
                        name={name}
                        checked={value}
                        onChange={handleToggle}
                    />
                    <label htmlFor={name} className="checkbox-label"></label>
                </div>
            </div>
        );
    };

    return (
        <div className="form-container">
            <ToastContainer />
            <PlayerSearch onSelect={handleSelect} />
            {player.id && (
                <form onSubmit={handleSubmit} className="player-form">
                    <div className="form-columns">
                        <div className="left-column">
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Name"
                                        value={player.name}
                                        onChange={handleChange}
                                        required
                                        className="form-input"
                                    />
                                </div>
                                <SelectField
                                    label="Country"
                                    name="country"
                                    value={player.country}
                                    onChange={handleChange}
                                    placeholder="Select a country"
                                    options={Object.entries(countries).map(([code, name]) => ({
                                        value: code,
                                        label: name
                                    }))}
                                />
                            </div>

                            <div className="form-row">
                                <SelectField
                                    label="Specific Position"
                                    name="specificPositions"
                                    value=""
                                    onChange={(e) => handleMultiSelectChange(e, 'specificPositions')}
                                    placeholder="Select a specific position"
                                    options={Object.keys(specificPositions).map(pos => ({ value: pos, label: pos }))}
                                    multiple
                                    selectedItems={player.specificPositions}
                                    onRemove={handleRemoveSelected}
                                />
                                <SelectField
                                    label="Position"
                                    name="position"
                                    value={player.position}
                                    onChange={handleChange}
                                    placeholder="Select a position"
                                    options={Object.keys(positions).map(pos => ({ value: pos, label: pos }))}
                                />
                            </div>
                            <div className="form-row">

                                <div className="form-group">
                                    <label htmlFor="birthDate">Birth Date</label>
                                    <input
                                        type="date"
                                        name="birthDate"
                                        id="birthDate"
                                        value={player.birthDate}
                                        onChange={handleChange}
                                        required
                                        className="form-input"
                                    />
                                </div>
                                <SelectField
                                    label="Coaches"
                                    name="coaches"
                                    value=""
                                    onChange={(e) => handleMultiSelectChange(e, 'coaches')}
                                    placeholder="Select coaches"
                                    options={Object.keys(coaches).map(coach => ({ value: coach, label: coach }))}
                                    multiple
                                    selectedItems={player.coaches}
                                    onRemove={(item) => handleRemoveSelected(item, 'coaches')}
                                />
                            </div>

                            <div className="form-row">
                                <SelectField
                                    label="Clubs"
                                    name="clubs"
                                    value=""
                                    onChange={(e) => handleMultiSelectChange(e, 'clubs')}
                                    placeholder="Select clubs"
                                    options={Object.keys(clubs).map(club => ({ value: club, label: club }))}
                                    multiple
                                    selectedItems={player.clubs}
                                    onRemove={(item) => handleRemoveSelected(item, 'clubs')}
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="image">Image URL</label>
                                    <input
                                        type="text"
                                        name="image"
                                        id="image"
                                        placeholder="Image URL"
                                        value={player.image}
                                        onChange={handleChange}
                                        className="form-input"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="right-column">
                            <div className="check-grid">
                                <CheckToggle
                                    label="Argentina Cup"
                                    name="argentinaCup"
                                    value={player.argentinaCup}
                                    onChange={handleChange}
                                />
                                <CheckToggle
                                    label="World Champion"
                                    name="worldChampion"
                                    value={player.worldChampion}
                                    onChange={handleChange}
                                />
                                <CheckToggle
                                    label="Copa America"
                                    name="copaAmerica"
                                    value={player.copaAmerica}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="form-button">Update Player</button>
                    <button type="button" onClick={handleCancel} className="form-button">Cancel</button>
                    {originalPlayer && (
                        <button type="button" onClick={handleDelete} className="form-button">Delete</button>
                    )}
                </form>
            )}
        </div>
    );
};


export default EditPlayerFormArg;
